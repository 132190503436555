import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link, { navigateTo } from 'gatsby-link'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import axios from 'axios'

import TemplateWrapper from '../components/layout'
import Title from '../components/title'

class BlogIndex extends Component {

  _renderPost(el, i) {
    let linkProps = {
      to: `/blog/${el.node.slug}/`,
    }
    return (
      <Reveal effect="fadeInLeft" key={i}>
        <div className='r3__blog__list'>
          <Link {...linkProps} className='r3__blog__list__heading'>
            <Title text={el.node.title} />
          </Link>
          <div dangerouslySetInnerHTML={{__html: el.node.acf.excerpt}} />
          <Link {...linkProps}>
            Read more
          </Link>
        </div>
      </Reveal>
    )
  }

  render() {
    let data = this.props.data.allWordpressPost.edges

    return (
      <TemplateWrapper {...this.props}>
        <Helmet bodyAttributes={{
          class: 'blog'
        }}>
          <title>Blog Archive</title>
        </Helmet>
        <div>
          <div className="r3__section r3--dark r3--no-head">
            <div className="r3__content">
              <Reveal effect="fadeInLeft">
                <h1>Blog Archive</h1>
              </Reveal>
            </div>
          </div>

          { data.length > 0 &&
            <div className="r3__section">
              <div className="r3__content">
                { data.map(this._renderPost) }
              </div>
            </div>
          }

        </div>
      </TemplateWrapper>
    )
  }

}

export default BlogIndex

export const postQuery = graphql`
  query wordpressPosts {
    allWordpressPost(sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          date
          slug
          categories {
            name
            slug
          }
          acf {
            excerpt
          }
        }
      }
    }
  }
`
